// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
const theme = {
  colors: {
    'primary-orange': {
        '50': '#fff7f2', 
        '100': '#ffefe6', 
        '200': '#ffd6bf', 
        '300': '#ffbe99', 
        '400': '#ff8d4d', 
        '500': '#FF5C00', 
        '600': '#e65300', 
        '700': '#bf4500', 
        '800': '#993700', 
        '900': '#7d2d00'
    }
  },
}

export default extendTheme(theme)